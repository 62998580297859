<template>
  <div>测试 VUE3_TS_{{ test }}</div>
</template>
<script >
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { ref, computed } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    useState,
    useActions
  } = createNamespacedHelpers('patient');
  console.log(this, 909999);

  // custom: (state) => state.patient.customcustom,
  const {
    custom
  } = useState(['custom']);
  console.log(custom.value, 'customVluecustomVlue');
  const test = ref(1);
  test.value = 2;
  return {
    test
  };
};
export default __sfc_main;
</script>
